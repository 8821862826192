import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";

import { EcosystemId, EcosystemLogo } from "~/components/logos/ecosystem";
import { container, item } from "~/components/site/layout";

const customers: EcosystemId[] = [
  "canton-network",
  "coinflow",
  "digital-asset",
  "ethereum-classic",
  "etherfuse",
  "fern",
  "glo-dollar",
  "meld",
  "perena",
  "rain",
  "stellar",
];

const DISPLAY_COUNT = 6;
const ROTATION_INTERVAL = 5000;

export function CustomerLogos(): JSX.Element {
  const [displayedCustomers, setDisplayedCustomers] = useState<EcosystemId[]>(
    []
  );
  const lastIndexRef = useRef<number>(-1);

  const initialCustomers = useMemo(
    () =>
      [
        "glo-dollar",
        "rain",
        "canton-network",
        "coinflow",
        "perena",
        "meld",
      ] as EcosystemId[],
    []
  );

  useEffect(() => {
    setDisplayedCustomers(initialCustomers);

    const rotateCustomer = () => {
      setDisplayedCustomers((current) => {
        const availableCustomers = customers.filter(
          (c) => !current.includes(c)
        );

        if (availableCustomers.length === 0) return current;

        const newLogo =
          availableCustomers[
            Math.floor(Math.random() * availableCustomers.length)
          ];

        const replaceIndex = Math.floor(Math.random() * DISPLAY_COUNT);
        lastIndexRef.current = replaceIndex;

        return current.map((c, i) => (i === replaceIndex ? newLogo : c));
      });
    };

    const interval = setInterval(rotateCustomer, ROTATION_INTERVAL);
    return () => clearInterval(interval);
  }, [initialCustomers]);

  return (
    <motion.div
      variants={container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 justify-evenly lg:justify-between items-center mx-auto gap-y-8 gap-x-6"
    >
      {displayedCustomers.map((c, i) => (
        <motion.div variants={item} key={i}>
          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              key={c}
              variants={item}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className="flex justify-center items-center"
            >
              <EcosystemLogo ecosystemId={c} className="object-contain" />
            </motion.div>
          </AnimatePresence>
        </motion.div>
      ))}
    </motion.div>
  );
}
